<template>
    <v-app>
        <v-navigation-drawer dark v-model="drawer" v-if="user" class="sidebar-background" color="blue-grey" app>
            <Menu></Menu>
            <template #append>
                <v-card tile class="pa-2 logos text-center">
                    <a href="https://mastersoft.it" target="_b lank"><img alt="master soft" src="@/assets/loghi/mastersoft.png" width="150"></a>
                </v-card>
            </template>
        </v-navigation-drawer>

        <v-app-bar color="deep-purple-lighten-5" dark v-if="user" app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ user.username }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip color="blue darken-3" small>{{ user.ruolo }}</v-chip>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main class="grey lighten-2">

            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-snackbar v-model="snackbarShow">
            <div class="text-center">{{ snackbarMessage }}</div>
        </v-snackbar>

        <v-dialog v-model="dialogError">
            <v-card>
                <v-card-title>Errore del server</v-card-title>
                <v-card-text>
                    <div v-html="error" class="ma-3"></div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogError=false">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import Menu from "@/components/Menu";
import EventBus from "@/plugins/eventbus";
import {mapMutations} from "vuex";


export default {
    name: 'App',
    components: {Menu},
    data: () => ({
        drawer: null,
        dialogError: false,
        error: '',
        snackbarShow: false,
        snackbarMessage: null,
    }),
    async mounted() {
        await this.loadDataset();
        await this.checkLogin();
        EventBus.$on('notify', this.notify);
        EventBus.$on('logout', this.logout);
        EventBus.$on('show-error', this.showError);
    },
    methods: {
        ...mapMutations(['setDataset']),
        async loadDataset() {
            const res = await this.$http.get('/backend/dataset');
            if (res.ok) {
                for (const item of res.result.data) {
                    this.setDataset(item);
                }
            }
        },
        async checkLogin(redir) {
            const res = await this.$http.get('/backend/info', this.params);
            if (!res.ok) {
                this.logout();
                await this.$router.push({name: 'login'});
                return;
            }
            if (redir) {
                await this.$router.push({name: 'home'});
            }
        },
        showError(content) {
            this.error = content;
            this.dialogError = true;
        },
        notify(message) {
            this.snackbarMessage = message;
            this.snackbarShow = true;
        },
        logout() {
            this.$store.commit('logout');
        }
    },
    watch: {
        user() {
            this.checkLogin(true);
        }
    }
};
</script>

<style>

.v-application {
    font-weight: 300 !important;
}

.sidebar-background {
    //background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url('../public/background.jpg');
    background-position: top left, bottom left;
}

.v-data-table thead th {
    font-size: inherit !important;
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

.v-data-table thead tr {
    background-color: #f4f4f4;
}

.logos {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

</style>