<template>
    <v-text-field v-model="date" :label="label" readonly clearable @click:clear="picker = null" filled
                  dense
                  outlined>
        <template #append-outer>
            <v-menu v-model="menu" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" dark elevation="0" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <v-date-picker v-model="picker" @click="menu = false"/>
            </v-menu>
        </template>
    </v-text-field>
</template>
<script>
import moment from "moment";

export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        label: String,
    },
    data() {
        return {
            date: '',
            menu: false
        };
    },
    mounted() {
        this.to_date(this.value);
    },
    computed: {
        picker: {
            get() {
                return this.value;
            },
            set(val) {
                console.log(val);
                this.menu = false;
                this.to_date(val);
                this.$emit("input", val);
            }
        }
    },
    methods: {
        to_date(val) {
            if (!val) {
                this.date = '';
                return;
            }
            const data = moment(val);
            this.date = data.format('DD-MM-YYYY');
        }
    },
    watch: {
        value(v) {
            this.date = v;
        },
    }
};
</script>